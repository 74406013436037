<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="role">
        <div class="box">
            <p class="tit">角色</p>
            <div class="box_nei">
                <p @click="juese_zu=true">新增角色组</p>
                <p @click="xinzeng_juesea">新增角色</p>
            </div>
            <!-- <p style="height:0.6rem;line-height:0.6rem;font-size:0.12rem;color:1A2533;border-bottom:0.01rem solid #ccc;text-align:center;">暂无数据</p> -->
            <div class="tree">
                <el-tree :data="juese_lista" :props="defaultProps">
                    <div class="custom-tree-node"  slot-scope="{ node, data }" @click="hang_dianji(data)">
                        <p :style="data.children==undefined?'color:#4C4A4D':'font-weight:bold;color:#1A2533'"><img :src="data.children==undefined?require('../../assets/huang_wenjian.png'):''" alt="">{{ node.label }}</p>
                        <p v-if="node.label!='默认角色组'&&node.label!='负责人'&&node.label!='主管理员'&&node.label!='子管理员'&&node.label!='主管'" @click.stop='jinxing_bianji(data)'>编辑</p>
                        <!-- <p><span @click.stop='quxiao_tianjia_mubiao(data)' :style="data.aim_prop==1?'color:#A5C6FD':'color:#FFB466'">{{data.aim_prop==1?'取消目标':'添加目标'}}</span><span @click.stop='tingyong_qiyong_mubiao(data)' :style="data.active==1?'color:#A5C6FD':'color:#8d52e3'">{{data.active==1?'停用':'启用'}}</span></p> -->
                    </div>
                </el-tree>
            </div>
            <div class="box_nei_2">
                <span @click="bianji_renyuan=true">新增角色人员</span>
                <span @click="dianji_shanchu">删除</span>
            </div>
            <el-table :cell-style="liebiao" :header-cell-style="biaotou" :data="renyuan_list" tooltip-effect="dark" style="width: 100%" @selection-change="dianji_xuanze_renyuan">
                <el-table-column type="selection" width="42">
                </el-table-column>
                <el-table-column  prop="staff_name" label="姓名">
                </el-table-column>
                <el-table-column  prop="role_name" label="角色">
                </el-table-column>
            </el-table>
            <el-drawer
                :visible.sync="juese_zu"
                :modal='false'
                :show-close='false'
                title="新增角色组"
                size="620">
                <div style="color:#888888;font-size:0.14rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:3.88rem;">角色组信息</div>
                <ul class="liebiao">
                    <li class="dd1">
                        <span>*</span>角色组名称
                    </li>
                    <li class="dd2"><input type="text" placeholder='请输入角色组名称' v-model="juese_name" ></li>
                </ul>
                <div class="xuan_foot">
                    <div style="color:#fff;background:#8d52e3" @click="dianji_xinzeng_zu">确定</div>
                    <div style="color:#888888;border:0.01rem solid #888888" @click="juese_zu=false">取消</div>
                </div>
            </el-drawer>
            <el-drawer
                :visible.sync="juese_name1"
                :modal='false'
                :show-close='false'
                title="新增角色"
                size="620">
                <!-- <div style="color:#888888;font-size:0.18rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:380.08rem;">角色组信息</div> -->
                <div style="color:#888888;font-size:0.14rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:3.88rem;">角色组信息</div>
                <ul class="liebiao">
                    <li class="dd1">
                        <span>*</span>角色信息
                    </li>
                    <li class="dd2"><input type="text" placeholder='请输入角色名称' v-model="juese_name2" ></li>
                    <li class="dd1">
                        <span>*</span>分组到
                    </li>
                    <li class="dd2">
                        <el-select v-model="fenpei_juese_zu">
                            <el-option
                            v-for="(i,index) in juese_list"
                            :key="index"
                            :label="i.label"
                            :value="i.id">
                            </el-option>
                        </el-select>
                    </li>
                </ul>
                <div class="xuan_foot">
                    <div style="color:#fff;background:#8d52e3" @click="dianji_xinzeng_ming">确定</div>
                    <div style="color:#888888;border:0.01rem solid #888888" @click="juese_name1=false">取消</div>
                </div>
            </el-drawer>
            <el-drawer
                :visible.sync="bianji_juese_1"
                :modal='false'
                :show-close='false'
                :title='wenzi'
                size="620">
                <div style="color:#888888;font-size:0.14rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:3.88rem;">{{wenzi2}}</div>
                <ul class="liebiao" v-if="wenzi=='编辑角色组'">
                    <li class="dd1">
                        <span>*</span>角色组信息
                    </li>
                    <li class="dd2"><input type="text" placeholder='请输入角色组名称' v-model="juese_name" ></li>
                </ul>
                <ul class="liebiao" v-if="wenzi=='编辑角色'">
                    <li class="dd1">
                        <span>*</span>角色信息
                    </li>
                    <li class="dd2"><input type="text" placeholder='请输入角色名称' v-model="juese_name2" ></li>
                    <li class="dd1">
                        <span>*</span>分组到
                    </li>
                    <li class="dd2">
                        <el-select v-model="fenpei_juese_zu">
                            <el-option
                            v-for="(i,index) in juese_list"
                            :key="index"
                            :label="i.label"
                            :value="i.id">
                            </el-option>
                        </el-select>
                    </li>
                </ul>
                <div class="xuan_foot">
                    <div style="color:#fff;background:#8d52e3" @click="dianji_bianji_ming">确定</div>
                    <div style="color:#888888;border:0.01rem solid #888888" @click="dianji_bianji_shanchu">删除</div>
                    <div style="color:#888888;border:0.01rem solid #888888" @click="bianji_juese_1=false">取消</div>
                </div>
            </el-drawer>
            <el-drawer
                :visible.sync="bianji_renyuan"
                :modal='false'
                :show-close='false'
                title='人员'
                size="620">
                <div style="color:#888888;font-size:0.14rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:3.88rem;">人员信息</div>
                <ul class="liebiao">
                    <li class="dd1">
                        <span>*</span>选择角色
                    </li>
                    <li class="dd2">
                        <input type="text" placeholder='请选择角色' @click="dakai_juese" v-model="xuanze_juese_name" >
                        <!-- <el-select v-model="juese_xuanze">
                            <el-option
                            v-for="(i,index) in juese_xuanze_list"
                            :key="index"
                            :label="i.label"
                            :value="i.id">
                            </el-option>
                        </el-select> -->
                    </li>
                    <li v-if="juese_xuanze!=null&&juese_xuanze.length!=0" class="dd1">
                        <span>*</span>选择人员
                    </li>
                    <li v-if="juese_xuanze!=null&&juese_xuanze.length!=0" class="dd2"><input type="text" placeholder='请选择人员' @click="dakai_bumen" v-model="ziguanliyuan" ></li>
                </ul>
                <div class="xuan_foot">
                    <div style="color:#fff;background:#8d52e3" @click="dianji_xinzeng_renyuan_queding">确定</div>
                    <!-- <div style="color:#888888;border:0.01rem solid #888888" @click="dianji_bianji_shanchu">删除</div> -->
                    <div style="color:#888888;border:0.01rem solid #888888" @click="bianji_renyuan=false">取消</div>
                </div>
            </el-drawer>
            <el-dialog title="选择人员" :center="true" :show-close='false' :visible.sync="qiye_bumen">
                <div class="tiao_bumen">
                    <div class="xuanze">
                        <p class="tit">选择：</p>
                        <div class="xuanze_sousuo">
                            <input type="text" placeholder="搜索" @keyup="keyu" v-model="text">
                            <i class="el-icon-search"></i>
                        </div>
                        <div class="mianbaoxie">
                            <div v-for="(i,index) in mianbao_list" :key='index'  @click="mianbao_dian(i,index)">
                                <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                        <div class="quanxuan" v-if="qiye_renyuan_list.length!=0">
                            <!-- <input type="checkbox" @click="dianji_quanxuan" ref='quan'> -->
                            <img @click="dianji_quanxuan" :src="renyuan_quanxuan?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                            <span>全选</span>
                        </div>
                        <div class="nei_box">
                            <div class="liebiao">
                                <ul>
                                    <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)" v-if="depmented">
                                        <p style="margin-left:0.16rem;">{{i.dept_name}}</p>
                                        <img class="img1" :src="i.child_flag=='0'?require('../../assets/chazi.png'):require('../../assets/chazi_ok.png')" alt="">
                                    </li>
                                </ul>
                                <ul>
                                    <li v-for="(i,index) in qiye_renyuan_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                        <!-- <input ref="have" type="checkbox" @click="dianji_xuanren(i,$event,index)"> -->
                                        <img class="img2" @click="dianji_xuanren(i)" :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                        <p>{{i.name}}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="yixuan">
                        <p class="tit">已选：</p>
                        <div class="nei_box">
                            <div class="liebiao">
                                <ul>
                                    <li v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                        <p>{{i.name}}</p>
                                        <i @click="dianji_shanchu_yixuan_ren(index)" class="el-icon-circle-close"></i>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="foote">
                    <p class="queding" @click="dianji_queding">确定</p>
                    <p class="quxiao" @click="qiye_bumen=false">取消</p>
                </div>
            </el-dialog>
            <el-dialog title="选择角色" :center="true" :show-close='false' :visible.sync="qiye_juese">
                <div class="tiao_bumen">
                    <div class="xuanze">
                        <p class="tit">选择：</p>
                        <div class="mianbaoxie">
                            <div v-for="(i,index) in mianbao_list_jue" :key='index'  @click="mianbao_dian_jue(i,index)">
                                <p :class="mianbao_list_jue.length-1==index?'p1':'p2'">{{i.name}}</p>
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                        <!-- <div class="quanxuan"><input type="checkbox" @click="dianji_quanxuan_jue" ref='quan_jue'><span>全选</span></div> -->
                        <div class="nei_box">
                            <div class="liebiao">
                                <ul>
                                    <li v-for="(i,index) in qiye_juese_list" :key="index" @click="dianji_bumen_jue(i)">
                                        <p style="margin-left:0.16rem;">{{i.role_group_name}}</p>
                                        <!-- <img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt=""> -->
                                    </li>
                                </ul>
                                <ul>
                                    <li v-for="(i,index) in qiye_juesery_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                        <!-- <input ref="have_jue" type="checkbox" @click="dianji_xuanren_jue(i,$event,index)"> -->
                                        <img class="img2" @click="dianji_xuanren_jue(i,index)" :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                        <p>{{i.role_name}}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="yixuan">
                        <p class="tit">已选：</p>
                        <div class="nei_box">
                            <div class="liebiao">
                                <ul>
                                    <li v-for="(i,index) in qiye_juesery_list_xuan" :key="index">
                                        <p>{{i.role_name}}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="foote">
                    <p class="queding" @click="juese_queding">确定</p>
                    <p class="quxiao" @click="qiye_juese=false">取消</p>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { query_role_info_list, delete_staff_role_rel, query_user_list_of_role, query_ent_dept_list, query_dept_staff_list, get_staff_list_like, set_staff_role_rel, delete_role_info, delete_role_group_info, update_role_info, update_role_group_info, insert_role_info, query_role_group_info_list, insert_role_group_info } from '../../api/api.js'
export default {
  name: 'role',
  data () {
    return {
      juese_list: [],
      juese_lista: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      juese_zu: false,
      juese_name: '',
      juese_name1: false,
      juese_name2: '',
      fenpei_juese_zu: '',
      wenzi: '',
      wenzi2: '',
      bianji_juese_1: false,
      bianji_id: '',
      bianji_renyuan: false,
      juese_xuanze_list: [],
      juese_xuanze: '',
      qiye_bumen: false,
      mianbao_list: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      qiye_bumen_list: [],
      depmented: true,
      qiye_renyuan_list: [],
      qiye_renyuan_list_xuan: [],
      text: '',
      ziguanliyuan_bei: [],
      ziguanliyuan: '',
      renyuan_list: [],
      multipleSelection: '', // 栈选择多选人员
      juese_id: '',

      // 4.9新构
      renyuan_quanxuan: false,
      juese_sousuo: '',
      mianbao_list_jue: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      qiye_juese_list: [],
      qiye_juesery_list_xuan: [],
      qiye_juese: false,
      qiye_juesery_list: [],
      juese_num: '0',
      xuanze_juese_name: '',

      // 2020/5/31
      fanxie_renyuan_list: []
    }
  },
  watch: {
    bianji_juese_1 () {
      if (this.bianji_juese_1 == false) {
        this.juese_name = ''
        this.juese_name2 = ''
        this.fenpei_juese_zu = ''
      }
    },
    // qiye_renyuan_list_xuan(){
    //     if(this.qiye_renyuan_list_xuan.length!=0){
    //         if(this.qiye_renyuan_list_xuan.length==this.qiye_renyuan_list.length){
    //             this.$refs.quan.checked=true
    //         }else{
    //             this.$refs.quan.checked=false
    //         }
    //     }
    // },
    bianji_renyuan () {
      if (!this.bianji_renyuan) {
        this.ziguanliyuan_bei = []
        this.ziguanliyuan = ''
        this.text = ''
        this.qiye_renyuan_list_xuan = []
        this.qiye_juesery_list_xuan = []
        this.xuanze_juese_name = ''
        this.juese_xuanze = ''
      }
    }
    // qiye_renyuan_list:{
    //     handler: function(val, oldVal){
    //         this.panduan_shifou_quanxuan();
    //     },
    // }
    // // panduan_shifou_quanxuan
  },
  mounted () {
        
  },
  created () {
    this.jichu()
    this.mianbao_list[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
    this.mianbao_list_jue[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
  },
  methods: {
    // 获取基础信息
    jichu () {
      query_role_group_info_list({
        data: {
          ent_id: this.$ent_id(),
          active: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.juese_list = []
          this.juese_lista = []
          const juese_xuanze_list = []
          for (let i = 0; i < date.length; i++) {
            this.juese_list.push({
              label: date[i].role_group_name,
              id: date[i].role_group_id,
              children: []
            })
            for (let a = 0; a < date[i].role_list.length; a++) {
              date[i].role_list[a].label = date[i].role_list[a].role_name
              date[i].role_list[a].id = date[i].role_list[a].role_id
              juese_xuanze_list.push(date[i].role_list[a])
            }
            this.juese_list[i].children = date[i].role_list
            this.juese_lista.push(this.juese_list[i])
            console.log(this.juese_lista)
          }
          this.juese_xuanze_list = juese_xuanze_list
        } else if (res.data.code == 500) {}
      })
    },
    // 新增角色组
    dianji_xinzeng_zu () {
      if (this.juese_name == '') {
        this.$message({
          message: '还未填写角色组的名称',
          type: 'warning'
        })
      } else if (this.juese_name != '') {
        insert_role_group_info({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            role_group_name: this.juese_name
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.juese_zu = false
            this.jichu()
            this.juese_name = ''
          } else if (res.data.code == 500) {}
        })
      }
    },
    // 新增角色
    xinzeng_juesea () {
      if (this.juese_list.length == 0) {
        this.$message({
          message: '还未有角色组，请去建角色组吧',
          type: 'warning'
        })
      } else {
        this.juese_name1 = true
      }
    },
    dianji_xinzeng_ming () {
      if (this.juese_name2 == '' || this.fenpei_juese_zu == '') {
        this.$message({
          message: '请填写完整内容',
          type: 'warning'
        })
      } else {
        insert_role_info({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            role_name: this.juese_name2,
            role_group_id: this.fenpei_juese_zu
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.juese_name1 = false
            this.jichu()
            this.juese_name2 = ''
            this.fenpei_juese_zu = ''
          } else if (res.data.code == 500) {}
        })
      }
    },
    handleNodeClick (data) {
      console.log(data)
    },
    // 角色旁边的编辑按钮
    jinxing_bianji (i) {
      this.bianji_juese_1 = true
      console.log(i)
      this.juese_name2 = i.label
      this.fenpei_juese_zu = i.fu_id
      this.juese_name = i.label
      this.bianji_id = i.id
      if (i.children != undefined) {
        this.wenzi = '编辑角色组'
        this.wenzi2 = '角色组信息'
      } else {
        this.fenpei_juese_zu = i.role_group_id
        this.wenzi = '编辑角色'
        this.wenzi2 = '角色信息'
      }
    },
    // 编辑里面的确定
    dianji_bianji_ming () {
      if (this.wenzi == '编辑角色组') {
        if (this.juese_name != '') {
          update_role_group_info({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              role_group_id: this.bianji_id,
              role_group_name: this.juese_name
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              console.log(this.juese_lista)
              this.juese_lista.forEach(item => {
                if (this.bianji_id == item.id) {
                  item.label = this.juese_name
                  this.$forceUpdate()
                }
              })
              this.bianji_juese_1 = false
              // this.jichu()
            } else if (res.data.code == 200) {}
          })
        }
      } else if (this.wenzi == '编辑角色') {
        if (this.juese_name2 != '' && this.fenpei_juese_zu != '') {
          update_role_info({
            data: {
            // post_id:this.bianji_id,
              role_name: this.juese_name2,
              role_id: this.bianji_id,
              role_group_id: this.fenpei_juese_zu,
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.juese_lista.forEach(item => {
                for (let i = 0; i < item.children.length; i++) {
                  if (item.children[i].id == this.bianji_id) {
                    item.children.splice(i, 1)
                    i = i - 1
                    console.log(item)
                  }
                }
                if (item.id == this.fenpei_juese_zu) {
                  item.children.push({
                    active: '1',
                    ent_id: this.$ent_id(),
                    id: this.bianji_id,
                    label: this.juese_name2,
                    role_group_id: item.id,
                    role_group_name: item.label,
                    role_id: this.bianji_id,
                    role_name: this.juese_name2
                  })
                }
              })
              this.bianji_juese_1 = false
              // this.jichu()
            } else if (res.data.code == 500) {}
          })
        }
      }
    },
    dianji_bianji_shanchu () {
      if (this.wenzi == '编辑角色组') {
        if (this.juese_name != '') {
          delete_role_group_info({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              role_group_ids: this.bianji_id
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.bianji_juese_1 = false
              this.jichu()
            } else if (res.data.code == 200) {}
          })
        }
      } else if (this.wenzi == '编辑角色') {
        if (this.juese_name2 != '' && this.fenpei_juese_zu != '') {
          delete_role_info({
            data: {
              role_ids: this.bianji_id,
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.bianji_juese_1 = false
              this.jichu()
            } else if (res.data.code == 500) {}
          })
        }
      }
    },
    dakai_bumen () {
      // fanxie_renyuan_list
      const id_list = this.juese_xuanze.split(',')
      const renyuan_list = []
      id_list.forEach(item => {
        query_user_list_of_role({
          data: {
            ent_id: this.$ent_id(),
            role_id: item
          }
        }).then(ras => {
          console.log(ras)
          if (ras.data.code == 200) {
            const date = JSON.parse(ras.data.body.data)
            console.log(date)
            date.forEach(it => {
              this.$set(it, 'name', it.staff_name != undefined ? it.staff_name : '')
              this.$set(it, 'staff_id', it.user_id != undefined ? it.user_id : '')
              renyuan_list.push(it)
            })
            // 亲爱的下个人啊，当你走到这里的时候不要懵逼，这块是为了反写
            this.qiye_renyuan_list_xuan = this.$func.Es5duplicate(renyuan_list, 'user_id')
          } else if (ras.data.code == 500) {}
        })
      })
      this.mianbao_list.splice(1, this.mianbao_list.length)
      this.bumen_liebiao()
      this.qiye_bumen = true
    },
    // 部门列表
    bumen_liebiao () {
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          active: '1',
          parent_id: this.mianbao_list[this.mianbao_list.length - 1].dept_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_bumen_list = date
        } else if (res.data.code == 10169) {
        }
      })
      query_dept_staff_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          dept_id: this.mianbao_list[this.mianbao_list.length - 1].dept_id,
          active: '1'
        }
      }).then(ras => {
        if (ras.data.code == 10149) {
          const listdata = JSON.parse(ras.data.body.data)
          console.log(99999, listdata)
          listdata.map(item => {
            this.$set(item, 'zhi', false)
          })
          this.qiye_renyuan_list = listdata
          this.panduan_yixuan()
        } else if (ras.data.code == 10150) {
        }
      })
    },
    // // 点击全选
    // dianji_quanxuan(e){
    //     console.log(e)
    //     console.log(this.$refs.have)
    //     if(e.path[0].checked){
    //         for(let i=0;i<this.$refs.have.length;i++){
    //             this.$refs.have[i].checked=true
    //         }
    //         this.qiye_renyuan_list_xuan=[]
    //         for(let i=0;i<this.qiye_renyuan_list.length;i++){
    //             this.qiye_renyuan_list_xuan.push(this.qiye_renyuan_list[i])
    //         }
    //         console.log(this.qiye_renyuan_list_xuan)
    //     }else{
    //         for(let i=0;i<this.$refs.have.length;i++){
    //             this.$refs.have[i].checked=false
    //         }
    //         this.qiye_renyuan_list_xuan=[]
    //     }
    // },
    // // 单选人
    // dianji_xuanren(i,e,index){
    //     console.log(e)
    //     if(e.path[0].checked==true){
    //         this.qiye_renyuan_list_xuan.push(i)
    //     }else{
    //         console.log(this.qiye_renyuan_list_xuan)
    //         for(let i=0;i<this.qiye_renyuan_list_xuan.length;i++){
    //             if(this.qiye_renyuan_list[index].name==this.qiye_renyuan_list_xuan[i].name){
    //                 this.qiye_renyuan_list_xuan.splice(i,1)
    //             }
    //         }
    //     }
    // },
    // 点击部门
    dianji_bumen (i) {
      console.log(44444, i)
      // if(i.child_flag==1){
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          parent_id: i.dept_id,
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_bumen_list = date
          this.mianbao_list.push({ name: i.dept_name, dept_id: i.dept_id })
          this.inde = -1
          if (!this.bumen) {
            query_dept_staff_list({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id,
                dept_id: i.dept_id,
                active: '1'
              }
            }).then(ras => {
              if (ras.data.code == 10149) {
                const listdata = JSON.parse(ras.data.body.data)
                console.log(99999, listdata)
                listdata.map(item => { this.$set(item, 'zhi', false) })
                this.qiye_renyuan_list = listdata
                this.panduan_yixuan()
              } else if (ras.data.code == 10150) {
              }
            })
          }
        } else if (res.data.code == 10169) {
        }
      })
      // }else{
      //     this.$message({
      //         message: '该部门没有子部门了',
      //         type: 'warning'
      //     }); 
      // }
    },
    // 点击面包屑进行页面内容切换
    mianbao_dian (i, index) {
      console.log(i.dept_id)
      if (this.mianbao_list.length - 1 != index) {
        query_ent_dept_list({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            parent_id: i.dept_id,
            active: '1'
          }
        }).then(res => {
          if (res.data.code == 10168) {
            var date = JSON.parse(res.data.body.data)
            // console.log(date)
            this.qiye_bumen_list = date
            this.mianbao_list.splice(index + 1, this.mianbao_list.length)
            query_dept_staff_list({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id,
                dept_id: i.dept_id,
                active: '1'
              }
            }).then(ras => {
              console.log(ras)
              if (ras.data.code == 10149) {
                const listdata = JSON.parse(ras.data.body.data)
                console.log(99999, listdata)
                listdata.map(item => { this.$set(item, 'zhi', false) })
                this.qiye_renyuan_list = listdata
                this.panduan_yixuan()
              } else if (ras.data.code == 10150) {
              }
            })
          } else if (res.data.code == 10169) {
          }
        })
      }
    },
    // 点击确定
    dianji_queding () {
      if (this.qiye_renyuan_list_xuan.length != 0) {
        const list = []
        for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
          list.push(this.qiye_renyuan_list_xuan[i].name)
          this.ziguanliyuan_bei.push(this.qiye_renyuan_list_xuan[i])
        }
        this.ziguanliyuan = list.join(',')
        this.qiye_bumen = false
      } else {
        this.$message({
          message: '请选择人员',
          type: 'warning'
        }) 
      }
    },
    keyu () {
      if (this.text.length != 0) {
        get_staff_list_like({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            staff_name: this.text != 0 ? this.text : null,
            active: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10027) {
            console.log(JSON.parse(res.data.body.data))
            const date = JSON.parse(res.data.body.data)
            date.forEach(item => {
              this.$set(item, 'zhi', false)
              this.$set(item, 'name', item.staff_name)
            })
            this.depmented = false
            this.qiye_renyuan_list = date
            this.panduan_yixuan()
          } else if (res.data.code == 10028) {
          }
        })
      } else {
        this.qiye_renyuan_list = []
      }
    },
    dianji_xinzeng_renyuan_queding () {
      if (this.ziguanliyuan_bei.length != 0 && this.juese_xuanze.length != 0) {
        set_staff_role_rel({
          data: {
            ent_id: this.$ent_id(),
            role_ids: this.juese_xuanze,
            staff_ids: this.ziguanliyuan_bei.map(item => item.staff_id).join(',')
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.bianji_renyuan = false
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '请填写完整信息',
          type: 'warning'
        })
      }
    },
    // 行点击
    hang_dianji (i) {
      if (i.children == undefined) {
        console.log(i)
        this.juese_id = i.id
        query_user_list_of_role({
          data: {
            ent_id: this.$ent_id(),
            role_id: i.id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.renyuan_list = date
          } else if (res.data.code == 500) {}
        })
      }
    },
    dianji_xuanze_renyuan (val) {
      this.multipleSelection = val
      console.log(9999999, val)
    },
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'color:#1A2533;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'color:#4C4A4D;font-size:0.1rem;'
    },
    dianji_shanchu () {
      console.log(this.multipleSelection)
      if (this.multipleSelection.length != 0) {
        delete_staff_role_rel({
          data: {
            ent_id: this.$ent_id(),
            role_id: this.juese_id,
            staff_ids: this.multipleSelection.map(i => i.user_id).join(',')
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            query_user_list_of_role({
              data: {
                ent_id: this.$ent_id(),
                role_id: this.juese_id
              }
            }).then(ras => {
              console.log(ras)
              if (ras.data.code == 200) {
                const date = JSON.parse(ras.data.body.data)
                console.log(date)
                this.renyuan_list = date
                this.$message({
                  message: '删除成功',
                  type: 'success'
                })
              } else if (ras.data.code == 500) {}
            })
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '请选择角色进行删除',
          type: 'warning'
        })
      }
    },

    // 点击全选
    dianji_quanxuan () {
      this.renyuan_quanxuan = !this.renyuan_quanxuan
      if (this.renyuan_quanxuan) {
        this.qiye_renyuan_list.map(item => {
          this.$set(item, 'zhi', true)
          this.qiye_renyuan_list_xuan.push(item)
        })
        this.qiye_renyuan_list_xuan = this.$func.Es5duplicate(this.qiye_renyuan_list_xuan, 'staff_id')
      } else {
        this.qiye_renyuan_list.map(item => {
          this.$set(item, 'zhi', false)
        })
        for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
          for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
            if (this.qiye_renyuan_list[i].staff_id == this.qiye_renyuan_list_xuan[a].staff_id) {
              this.qiye_renyuan_list_xuan.splice(a, 1)
            }
          }
        }
      }
    },
    panduan_yixuan () {
      const list = []
      for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
        for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
          if (this.qiye_renyuan_list[i].staff_id == this.qiye_renyuan_list_xuan[a].staff_id) {
            this.$set(this.qiye_renyuan_list[i], 'zhi', true)
            list.push('1111')
          }
        }
      }
      if (this.qiye_renyuan_list.length != 0) {
        if (list.length == this.qiye_renyuan_list.length) {
          this.renyuan_quanxuan = true
        } else {
          this.renyuan_quanxuan = false
        }
      }
    },
    dianji_xuanren (i) {
      i.zhi = !i.zhi
      if (i.zhi) {
        this.qiye_renyuan_list_xuan.push(i)
      } else {
        for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
          if (i.staff_id == this.qiye_renyuan_list_xuan[a].staff_id) {
            this.qiye_renyuan_list_xuan.splice(a, 1)
          }
        }
      }
      this.panduan_shifou_quanxuan()
    },
    panduan_shifou_quanxuan () {
      const list = []
      console.log(this.qiye_renyuan_list)
      for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
        if (this.qiye_renyuan_list[i].zhi) {
          list.push('1111')
        }
      }
      if (this.qiye_renyuan_list.length != 0) {
        if (list.length == this.qiye_renyuan_list.length) {
          this.renyuan_quanxuan = true
        } else {
          this.renyuan_quanxuan = false
        }
      }
    },
    // 点击面包屑进行页面内容切换
    mianbao_dian_jue (i, index) {
      if (this.mianbao_list_jue.length - 1 != index) {
        query_role_group_info_list({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          if (res.data.code == 200) {
            var date = JSON.parse(res.data.body.data)
            // console.log(date)
            this.qiye_juese_list = date
            this.mianbao_list_jue.splice(index + 1, this.mianbao_list_jue.length)
            query_role_info_list({
              data: {
                ent_id: this.$ent_id(),
                role_group_id: this.mianbao_list_jue[this.mianbao_list_jue.length - 1].dept_id
              }
            }).then(ras => {
              if (ras.data.code == 200) {
                const listdata = JSON.parse(ras.data.body.data)
                console.log(99999, listdata)
                listdata.map(item => { this.$set(item, 'zhi', false) })
                this.qiye_juesery_list = listdata
              } else if (ras.data.code == 10150) {
              }
            })
          } else if (res.data.code == 500) {
          }
        })
      }
    },
    // keyu_jue () {
    //   if (this.juese_sousuo.length == 0) {
    //     this.juese_sousuo = null
    //   }
    //   query_role_info_list({
    //     data: {
    //       ent_id: this.$ent_id(),
    //       post_name: this.juese_sousuo
    //     }
    //   }).then(res => {
    //     console.log(res)
        
    //     if (res.data.code == 10027) {
    //       console.log(JSON.parse(res.data.body.data))
    //       const date = JSON.parse(res.data.body.data)
    //       date.map(item => { this.$set(item, 'zhi', false) })
    //       this.qiye_juesery_list = date
    //     } else if (res.data.code == 10028) {
    //     }
    //     if(res.data.code === '200') {
    //       const date = JSON.parse(res.data.body.data)
    //         this.mianbao_list = date
    //         console.log(this.mianbao_list, 11111)
    //       }
    //   })
    // },
    // 角色列表
    juese_liebiao () {
      query_role_group_info_list({
        data: {
          ent_id: this.$ent_id(),
          active: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_juese_list = date
        } else if (res.data.code == 500) {
        }
      })
      query_role_info_list({
        data: {
          ent_id: this.$ent_id(),
          role_group_id: this.mianbao_list_jue[this.mianbao_list_jue.length - 1].dept_id
        }
      }).then(ras => {
        if (ras.data.code == 200) {
          const listdata = JSON.parse(ras.data.body.data)
          console.log(99999, listdata)
          listdata.map(item => { this.$set(item, 'zhi', false) })
          this.qiye_juesery_list = listdata
        } else if (ras.data.code == 500) {
        }
      })
    },
    panduan_juese_xuanzhong () {
      for (let i = 0; i < this.qiye_juesery_list_xuan.length; i++) {
        for (let a = 0; a < this.qiye_juesery_list.length; a++) {
          if (this.qiye_juesery_list_xuan[i].role_id == this.qiye_juesery_list[a].role_id) {
            this.qiye_juesery_list[a].zhi = true
          }
        }
      }
    },
    dakai_juese () {
      this.qiye_juese = true
      this.mianbao_list_jue.splice(1, this.mianbao_list_jue.length)
      this.juese_liebiao()
    },
    // 选择角色确定
    juese_queding () {
      console.log(this.qiye_juesery_list_xuan)
      // role_name
      if (this.qiye_juesery_list_xuan.length != 0) {
        this.juese_xuanze = this.qiye_juesery_list_xuan.map(item => item.role_id).join(',')
        this.xuanze_juese_name = this.qiye_juesery_list_xuan.map(item => item.role_name).join(',')
      } else {
        this.juese_xuanze = null
        this.xuanze_juese_name = ''
      }
      this.qiye_juese = false
    },
    // // 单选人
    dianji_xuanren_jue (i, index) {
      // this.juese_num=index
      // console.log(index)
      console.log(i.zhi)
      // let list=[]
      // list.push(i)
      i.zhi = !i.zhi
      if (i.zhi) {
        if(this.qiye_juesery_list_xuan.length < 1) {
          this.qiye_juesery_list_xuan.push(i)
        } else {
          this.qiye_juesery_list_xuan.push(i)
          this.qiye_juesery_list_xuan[0].zhi = false
          this.qiye_juesery_list_xuan[1].zhi = true
          this.qiye_juesery_list_xuan.splice(0, 1)
          
        } 
      }
      // console.log(e)
      // if(e.path[0].checked==true){
      //     this.qiye_juesery_list_xuan.push(i)
      // }else{
      //     console.log(this.qiye_juesery_list_xuan)
      //     for(let i=0;i<this.qiye_juesery_list_xuan.length;i++){
      //         if(this.qiye_juesery_list[index].role_group_name==this.qiye_juesery_list_xuan[i].role_group_name){
      //             this.qiye_juesery_list_xuan.splice(i,1)
      //         }
      //     }
      // }
    },
    // 点击部门
    dianji_bumen_jue (i) {
      console.log(44444, i)
      this.qiye_juese_list = []
      this.mianbao_list_jue.push({ name: i.role_group_name, dept_id: i.role_group_id })
      query_role_info_list({
        data: {
          ent_id: this.$ent_id(),
          role_group_id: i.role_group_id
        }
      }).then(ras => {
        if (ras.data.code == 200) {
          const listdata = JSON.parse(ras.data.body.data)
          listdata.map(item => { this.$set(item, 'zhi', false) })
          console.log(99999, listdata)
          this.qiye_juesery_list = listdata
          this.panduan_juese_xuanzhong()
        } else if (ras.data.code == 500) {
        }
      })
    },
    dianji_shanchu_yixuan_ren (index) {
      this.qiye_renyuan_list_xuan.splice(index, 1)
            
      this.qiye_renyuan_list.forEach(item => {
        console.log(item)
        this.qiye_renyuan_list_xuan.forEach(it => {
          console.log(it)
          if (it.staff_id == item.staff_id) {
            this.$set(item, 'zhi', true)
            console.log(this.qiye_renyuan_list)
          } else {
            this.$set(item, 'zhi', false)
          }
        })
      })
      this.panduan_yixuan()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.role {
    height:100%;
    width:100%;
    .box{
        padding:  0 0.29rem;
        .tit{
            font-size: 0.18rem;
            color:#1A2533;
            text-align: center;
            padding-top:0.24rem;
            margin-bottom: 0.24rem;
        }
        .box_nei{
            height:0.42rem;
            display: flex;
            align-items: center;
            padding: 0 0.12rem;
            background:#E7E8EB;
            p{
                display: inline-block;
                padding: 0.04rem 0.12rem;
                font-size: 0.12rem;
                border-radius: 0.03rem;
                cursor:pointer;
                &:nth-child(1){
                    background:#8d52e3;
                    border:0.01rem solid #8d52e3;
                    color:#fff;
                    margin-right:0.18rem;
                }
                &:nth-child(2){
                    background:#fff;
                    border:0.01rem solid #A9A9A9;
                    color:#888888;
                }
            }
        }
        .box_nei_2{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height:0.42rem;
            padding: 0 0.12rem;
            background:#E7E8EB;
            span{
                padding: 0.04rem 0.12rem;
                font-size: 0.12rem;
                border-radius: 0.03rem;
                cursor:pointer;
                &:nth-child(1){
                    background:#8d52e3;
                    border:0.01rem solid #8d52e3;
                    color:#fff;
                    margin-right:0.18rem;
                }
                &:nth-child(2){
                    background:#fff;
                    border:0.01rem solid #A9A9A9;
                    color:#888888;
                }
            }
        }
        .custom-tree-node{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.14rem;
            padding-right: 0.08rem;
            img{
                height:0.11rem;
                width:0.14rem;
                margin: 0 0.2rem;
            }
            span{
                padding: 0 0.25rem 0 0.07rem;
            }
            p{
                &:nth-child(2){
                    font-size: 0.12rem;
                    color:#A5C6FD;
                    margin-right: 0.29rem;
                }
            }
        }
        .tiao_bumen{
            display: flex;
            align-items: center;
            justify-content:center;
            .xuanze,.yixuan{
                height:3.54rem;
                width:3.85rem;
                padding-top: 20px;
                background: #E7E8EB;
                margin: 0 0.11rem;
                margin-top: 0.2rem;
                position: relative;
                overflow-y:auto;
                &::-webkit-scrollbar {
                    width: 0.2rem;
                    height: 0.08rem;
                }
                
                &::-webkit-scrollbar-thumb {
                    border-radius: 0.12rem;
                    border: 0.06rem solid rgba(0, 0, 0, 0);
                    box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                }
                
                &::-webkit-scrollbar-thumb:hover {
                    box-shadow: 0.08rem 0 0 #4A4A4A inset;
                }
                .tit{
                    position: absolute;
                    left:0;
                    top:-0.5rem;
                    font-size: 0.14rem;
                    color:#1A2533;
                }
                .xuanze_sousuo{
                  background: #fff;
                  width: 100%;
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  input{
                    border: none;
                    width: 70%;
                    outline: none;
                  }
                }
                .mianbaoxie{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-bottom: 0.1rem;
                    margin-left: 0.23rem;
                    margin-top:0.12rem;
                    div{
                        display: flex;
                        align-items: center;
                        cursor:pointer;
                        p{
                            font-size: 0.14rem;
                        }
                        .p2{
                            color:#8d52e3;
                        }
                        .p1{
                            color:#1A2533;
                        }
                    }
                }
                .quanxuan{
                    display: flex;
                    align-items: center;
                    margin-left: 0.24rem;
                    input{
                        -webkit-appearance: none;
                        width: 0.18rem!important;
                        height: 0.17rem!important;
                        display: inline-block;
                        margin: -0.03rem 0.05rem 0 0!important;
                        cursor: pointer;
                        vertical-align: middle;
                        background:url(../../assets/noccc.png) no-repeat;
                        &:checked {
                            background: url(../../assets/okblue.png) no-repeat;
                        }
                        outline-color:none;
                        outline-style :none;
                        outline-width:none;
                    }
                    img{
                        height:0.16rem;
                        width:0.16rem;
                        margin-right: 0.08rem;
                        cursor: pointer;
                    }
                    span{
                        color:#1A2533;
                        font-size: 0.12rem;
                    }
                }
                .nei_box{
                    width:3.45rem;
                    margin: 0 auto;
                    max-height: 2.4rem;
                    overflow:auto;
                    // overflow-y: hidden;
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                        width: 0.1rem;
                        height: 0;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 0.12rem;
                        border: 0.06rem solid rgba(0, 0, 0, 0);
                        box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        box-shadow: 0.08rem 0 0 #4A4A4A inset;
                    }
                    .liebiao{
                        width:3.25rem;
                        margin: 0 auto;
                        ul{
                            li{
                                display: flex;
                                height:0.3rem;
                                justify-content: space-between;
                                align-items: center;
                                color:#1A2533;
                                font-size: 0.12rem;
                                padding-left: 0.1rem;
                                cursor:pointer;
                                &:hover{
                                    background: #eee;
                                }
                                .img1{
                                    height:0.11rem;
                                    width:0.2rem;
                                }
                                input{
                                    -webkit-appearance: none;
                                    width: 0.18rem!important;
                                    height: 0.17rem!important;
                                    display: inline-block;
                                    margin: -0.03rem 0.05rem 0 0!important;
                                    cursor: pointer;
                                    vertical-align: middle;
                                    background:url(../../assets/noccc.png) no-repeat;
                                    &:checked {
                                        background: url(../../assets/okblue.png) no-repeat;
                                    }
                                    outline-color:none;
                                    outline-style :none;
                                    outline-width:none;
                                }
                                .img2{
                                    height:0.16rem;
                                    width:0.16rem;
                                    margin-right: 0.08rem;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
            .yixuan{
                .nei_box{
                    width:3.45rem;
                    margin: 0 auto;
                    max-height: 3.5rem;
                    overflow:auto;
                    // overflow-y: hidden;
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                        width: 0.1rem;
                        height: 0;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 0.12rem;
                        border: 0.06rem solid rgba(0, 0, 0, 0);
                        box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        box-shadow: 0.08rem 0 0 #4A4A4A inset;
                    }
                    .liebiao{
                        width:3.25rem;
                        padding: 0.2rem 0;
                        margin: 0 auto;
                        ul{
                            li{
                                display: flex;
                                height:0.3rem;
                                justify-content: space-between;
                                align-items: center;
                                color:#1A2533;
                                font-size: 0.12rem;
                                padding-left: 0.1rem;
                                cursor:pointer;
                                &:hover{
                                    background: #eee;
                                    i{
                                        display: block;
                                    }
                                }
                                .img1{
                                    height:0.11rem;
                                    width:0.2rem;
                                }
                                i{
                                    font-size: 0.16rem;
                                    cursor: pointer;
                                    display: none;
                                }
                                input{
                                    -webkit-appearance: none;
                                    width: 0.18rem!important;
                                    height: 0.17rem!important;
                                    display: inline-block;
                                    margin: -0.03rem 0.05rem 0 0!important;
                                    cursor: pointer;
                                    vertical-align: middle;
                                    background:url(../../assets/noccc.png) no-repeat;
                                    &:checked {
                                        background: url(../../assets/okblue.png) no-repeat;
                                    }
                                    outline-color:none;
                                    outline-style :none;
                                    outline-width:none;
                                }
                            }
                        }
                    }
                }
            }
        }
        .foote{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.21rem;
            p{
                margin: 0 0.23rem;
                height:0.43rem;
                width:1.36rem;
                border-radius: 0.02rem;
                font-size: 0.14rem;
                line-height: 0.43rem;
                text-align: center;
                font-weight: bold;
                cursor:pointer;
            }
            .queding{
                color:#fff;
                background: #8d52e3;
            }
            .quxiao{
                color: #888888;
                border:0.01rem solid #888888;
                background: #fff;
            }
        }
    }
}
</style>
